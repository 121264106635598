footer {
  background-color: #0f1111;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
}

.footer-logo {
  text-align: center;
  line-height: 2;
}
.footer-logo .terms-and-privacy a {
  text-decoration: none;
  color: #0f79af;
}
.footer-logo .terms-and-privacy a:hover {
  color: #63b4dd;
}
.footer-logo .terms-and-privacy {
  display: flex;
  gap: 1rem;
}
.footer-logo .privacy p {
  color: #949494;
}

@media (min-width: 280px) and (max-width: 600px) {
  .terms-and-privacy {
    display: grid !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}/*# sourceMappingURL=footer.css.map */