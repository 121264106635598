nav{
    width: 100%;
    background:  #0f171e;
    position: fixed;
    top: 0;
    .nav{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        position: relative;
        z-index: -1;
    }
    .logo-div {
        img{
            width: 150px;
            object-fit: fill;
        }
    }
    .signin{
        a{
            color: rgba(242,244,246,.9);
            font-weight: 400;
        }
    }
    .globe-icon-section{
        display: flex;
        gap: .5rem;
    }
    .language-icon{
        span{
            font-size: 1rem;
            color: rgba(242,244,246,.9);
            font-weight: 500;
            position: relative;
            bottom: 7px;
        }
        a{
            text-decoration: none;
        }
    }
}

.language{
    padding: 2rem 0rem 2rem 3rem;
    background-color: #252e39;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3rem;
    max-height: 290px;
    box-shadow: 0 4px 8px #000;
    position: absolute;
    top: 4rem;
    right: 5rem;
    overflow: hidden;
    height: 100%;
    display: none;
    .language-first{
        border-right: 1px solid #111213;
        a{
            color: #c4cacf;
            text-decoration: none;
            line-height: 2;
            font-size: 1.2rem;
            font-weight: 400;
            
        }
        a:hover{
            background-color:  #0f171e;
            width: 100%;
        }
    }
    .language-first::-webkit-scrollbar{
        overflow-y: scroll;
        box-sizing: content-box;
        height: 100%;
    }
    .language-second{
        border-right: 1px solid #111213;
        a{
            color: #c4cacf;
            text-decoration: none;
            line-height: 2;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
    .language-second::-webkit-scrollbar{
        overflow-y: scroll;
        box-sizing: content-box;
        height: 100%;
    }
    .language-third{
        border-right: 1px solid #111213;
        a{
            color: #c4cacf;
            text-decoration: none;
            line-height: 2;
            font-size: 1.2rem;
            font-weight: 400;
        }
    }
    .language-third::-webkit-scrollbar{
        overflow-y: scroll;
        box-sizing: content-box;
        height: 100%;
    }
}

#en{
    text-decoration: none;
    color: rgba(242,244,246,.9);
    font-weight: 400;
}
@media (min-width: 320px) and (max-width: 480px){
    .nav{
        display: flex;
        flex-direction: column;
    }
    .language{
        // position: absolute !important;
        right: 0rem !important;
        top: 7.5rem;
        padding: 2rem 0rem 2rem 0rem !important;
        column-gap: 1rem;
    }
}