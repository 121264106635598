.sigup-section{
    text-align: center;
    padding-top: 1rem;
}
.form{
    h2{
        padding-right: 245px;
        padding-bottom: 1rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1;
    }
     input{
        width: 20rem;
        height: 2rem;
        border-radius: .2rem;
        outline-color: rgb(238, 179, 92);
        border: 1px solid #ddd;
        box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
     }
     label:nth-child(1){
        padding-right: 145px;
        font-weight: 700;
        font-size: 12px;
     }
     #password{
        padding-top: 1rem;
        font-weight: 700;
        font-size: 12px;
     }
     .pass{
      display: flex;
      justify-content: center;
      align-items: center;
      a{
         text-decoration: none !important;
         color: #0066c0;
         font-weight: 700;
      }
     }
     label{
      line-height: 2.5;
     }
}
.form-sign-in{
   border: 1px solid  #e7e9ec;
   width: 400px;
   margin-left: auto;
   margin-right: auto;
   padding: 2rem;
   margin-top: 1rem;
}
#submit{
   margin-top: 1.5rem;
   font-size: 1rem;
   font-weight: 400;
   background-color: #f0c14b;
   border-color: #a88734 #9c7e31 #846a29;
}
.privacy-notice{
   padding: 1rem;
   font-size: .8rem;
   text-align: left;
   font-weight: 400;
   position: relative;
   right: 10px;
   a{
      text-decoration: none;
      color: #0066c0;
   }
}
.keep-me{
   display: flex;
   align-items: center;
   font-size: .8rem;
   padding-left: 7px;
   input{
      width: 1rem;
      height: 1rem;
      margin-right: .3rem;
   }
}
.name{
   display: flex;
   align-items: center;
   justify-content: space-around;
   font-size: .8rem;
   line-height: 2;
   color: #767676;
   font-weight: 400;
   #line-amazon{
      width: 90px;
    border: 1px solid grey;
    border-top: none;
    border-left: none;
    border-right: none;
   }
   
}
#link{
   text-decoration: none;
}
#createaccount{
   background-color:  #e7e9ec;
   text-decoration: none;
   font-size: 1rem;
   color: #111;
   width: 315px;
   padding-top: 0.5rem;
   padding-bottom: 0.5rem;
   font-weight: 400;
   border: 1px solid grey;
}